import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
    const size = theme.typography.pxToRem(327);

    return {
        root: {
            width: size,
            height: size,
            borderRadius: '50%',
            backgroundColor: 'var(--secondary-light-color)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        rating: {
            fontSize: theme.typography.pxToRem(75),
            lineHeight: 94 / 75,
            fontWeight: theme.typography.fontWeightBold as number,
            marginBottom: theme.typography.pxToRem(11),
        },
        stars: {
            fontSize: theme.typography.pxToRem(32),
        },
    };
});
