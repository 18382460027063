import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from 'lib/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import initialize from 'lib/i18n';
import { LocalizeProvider } from 'react-localize-redux';
import moment from 'moment-timezone';
import theme from './theme';
import ToggleFullscreen from '../ToggleFullscreen';
import useLoadedMessage from '../../hooks/useLoadedMessage';

import Init from '../Init';
import SlideController from '../SlideController';

moment.locale('en');

const RELOAD_MINUTES = process.env.REACT_APP_RELOAD_MINUTES
    ? parseInt(process.env.REACT_APP_RELOAD_MINUTES, 10)
    : 0.1;

export default function App(): React.ReactElement {
    React.useEffect(() => {
        const timer = setTimeout(() => {
            window.location.reload();
        }, RELOAD_MINUTES * 60000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useLoadedMessage();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizeProvider initialize={initialize}>
                    <ApolloProvider client={client}>
                        <Router>
                            <Switch>
                                <Route exact path="/">
                                    <Init />
                                </Route>
                                <Route path="/:locationId">
                                    <SlideController />
                                </Route>
                            </Switch>
                            <ToggleFullscreen />
                        </Router>
                    </ApolloProvider>
                </LocalizeProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
