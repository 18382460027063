import { makeStyles } from 'tss-react/mui';
import { keyframes } from '@emotion/react';

const animationPercentLabel = keyframes`
    0% { 
        opacity: 0.0 
    }
    47% { 
        opacity: 0.0 
    }
    53% { 
        opacity: 1.0 
    }
    100% { 
        opacity: 1.0 
    }
`;

const animationLastMilestoneIcon = keyframes`
    0% { 
        top: 1rem;
        width: 2.4rem;
        height: 2.4rem;
        font-size: 2.4rem;
    }
    44.5% {
        top: 1rem;
        width: 2.4rem;
        height: 2.4rem;
        font-size: 2.4rem;
    }
    56.5% {
        top: 0.55rem;
        width: 1.2rem;
        height: 1.2rem;
        font-size: 1.2rem;
    }
    100% {
        top: 0.55rem;
        width: 1.2rem;
        height: 1.2rem;
        font-size: 1.2rem;
    }
`;

const animationFirstMilestoneIcon = keyframes`
    0% {
        top: 0.63rem;
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.6rem;
    }
    44.5% {
        top: 0.63rem;
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.6rem;
    }
    56.5% {
        top: 0.45rem;
        width: 0.9rem;
        height: 0.9rem;
        font-size: 0.9rem;
    }
    100% {
        top: 0.45rem;
        width: 0.9rem;
        height: 0.9rem;
        font-size: 0.9rem;
    }
`;

export const animationKeyframes = `
    @keyframes ${animationPercentLabel}
    @keyframes ${animationLastMilestoneIcon}
    @keyframes ${animationFirstMilestoneIcon}
`;

export const useStyles = makeStyles()((theme) => ({
    root: {
        position: 'relative',
        backgroundColor: 'var(--white-color)',
        height: theme.typography.pxToRem(30),
        borderRadius: theme.typography.pxToRem(4),
        marginBottom: theme.typography.pxToRem(8),
        marginRight: theme.typography.pxToRem(22),
        '&::after': {
            zIndex: 2,
            content: '""',
            position: 'absolute',
            right: theme.typography.pxToRem(-22),
            top: '-0.5rem',
            width: 0,
            height: 0,
            borderTop: `${theme.typography.pxToRem(24)} solid transparent`,
            borderLeft: `${theme.typography.pxToRem(32)} solid white`,
            borderBottom: `${theme.typography.pxToRem(24)} solid transparent`,
        },
    },
    hideLabel: {
        '& $bar span': {
            display: 'none',
        },
    },
    hidden: {
        display: 'none',
    },
    milestone: {
        '--milestone-position': 0,
        '--milestone-color': 'var(--white-color)',
        '--milestone-right': `100%`,
        '--milestone-delay': '0',
        '--milestone-transition': '2s',
        position: 'absolute',
        width: theme.typography.pxToRem(48),
        height: theme.typography.pxToRem(48),
        marginTop: theme.typography.pxToRem(-8),
        background: 'var(--white-color)',
        borderRadius: '50%',
        display: 'flex',
        overflow: 'visible',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.2em',
        transition: 'opacity 2s',
        left: 'var(--milestone-position)',

        '&:after': {
            top: 0,
            left: 0,
            bottom: 0,
            right: 'var(--milestone-right)',
            content: "''",
            display: 'block',
            position: 'absolute',
            background: 'var(--milestone-color)',
            transition: 'right .3s',
            transitionDelay: 'var(--milestone-delay)',
            borderRadius: '50%',
        },
    },
    lastMilestone: {
        width: theme.typography.pxToRem(68),
        height: theme.typography.pxToRem(68),
        marginTop: theme.typography.pxToRem(-18),
        '& > label': {
            top: theme.typography.pxToRem(70),
        },
    },
    milestoneBackgroundContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        borderRadius: '50%',
        overflow: 'hidden',
    },
    milestoneBackground: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(15,15,15,0.34)',
    },
    milestoneIcon: {
        position: 'absolute',
        zIndex: 1,
        fontSize: theme.typography.pxToRem(16),
        width: theme.typography.pxToRem(16),
        height: theme.typography.pxToRem(16),
        animationDuration: '5s',
        animationTimingFunction: theme.transitions.easing.easeInOut,
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
    animationFirstMilestoneIcon: {
        animationName: animationFirstMilestoneIcon,
    },
    animationLastMilestoneIcon: {
        animationName: animationLastMilestoneIcon,
    },
    milestoneLoading: {
        animationPlayState: 'paused',
    },
    milestonePercent: {
        animation: `${animationPercentLabel} 5s ${theme.transitions.easing.easeInOut} 0s infinite alternate`,
        zIndex: 1,
        fontWeight: 600,
        position: 'absolute',
    },
    milestonePercentBig: {
        fontSize: theme.typography.pxToRem(21),
        bottom: theme.typography.pxToRem(8),
    },
    milestonePercentSmall: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(16),
        bottom: theme.typography.pxToRem(2),
    },
    milestoneLabel: {
        position: 'absolute',
        top: theme.typography.pxToRem(50),
        fontSize: theme.typography.pxToRem(11),
        fontWeight: theme.typography.fontWeightMedium as number,
    },
    bar: {
        '--bar-width': 0,
        '--bar-color': '#b3cdda',
        '--bar-start-color': '#dae6ed',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        background:
            'linear-gradient(to right, var(--bar-start-color), var(--bar-color))',
        maxWidth: '100%',
        width: 'var(--bar-width)',
        borderTopLeftRadius: theme.typography.pxToRem(4),
        borderBottomLeftRadius: theme.typography.pxToRem(4),
        transition: 'width 2s',
        '& span': {
            display: 'block',
            fontSize: theme.typography.pxToRem(12),
            lineHeight: 1,
            color: 'var(--primary-color)',
            fontWeight: theme.typography.fontWeightMedium as number,
            position: 'absolute',
            top: `calc(50% - ${theme.typography.pxToRem(4)})`,
            left: theme.typography.pxToRem(15),
        },
        '$success & span': {
            color: 'var(--white-color)',
        },
    },
    success: {
        '& $bar': {
            backgroundColor: 'var(--primary-color)',
        },
    },
}));
