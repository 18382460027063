import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { locationUpdates } from 'api/queries';
import { LocationUpdate } from '@bondvet/types/settings';

type LocationUpdatesQuery = {
    locationUpdates: LocationUpdate[];
};

type LocationUpdatesVariables = {
    locationId: string;
};

export type LatestUpdatesData = {
    loading: boolean;
    runQuery: () => Promise<LocationUpdate[]>;
};

export default function useUpdates(locationId: string): LatestUpdatesData {
    const [run, { loading }] = useLazyQuery<
        LocationUpdatesQuery,
        LocationUpdatesVariables
    >(locationUpdates, {
        variables: { locationId },
        fetchPolicy: 'cache-and-network',
    });

    const runQuery = React.useCallback(async (): Promise<LocationUpdate[]> => {
        const { data } = await run({
            variables: { locationId },
            fetchPolicy: 'cache-and-network',
        });

        if (data?.locationUpdates) {
            return data.locationUpdates.filter(
                ({ displayInClinics, image }) =>
                    displayInClinics === true && image?.url,
            );
        }
        console.error('Could not load Location Updates');
        return [];
    }, [locationId, run]);

    return {
        loading,
        runQuery,
    };
}
