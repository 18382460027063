import colors from '@bondvet/web-app-lib/colors';
import { createTheme, alpha } from '@mui/material/styles';

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightBold = 600;

export default createTheme({
    typography: {
        fontFamily: "'Sailec', 'Helvetica', 'Arial', sans-serif",
        fontWeightLight,
        fontWeightRegular,
        fontWeightMedium,
    },
    shape: {
        borderRadius: 2,
    },
    palette: {
        primary: {
            main: colors.primary,
            light: colors.light,
            dark: colors.dark,
        },
        secondary: {
            main: colors.secondary,
            light: colors.secondary,
            dark: colors.secondary,
            contrastText: colors.white,
        },
        background: {
            default: colors.white,
        },
        error: {
            main: colors.error,
            light: colors.error,
            dark: colors.error,
            contrastText: colors.white,
        },
        text: {
            primary: colors.darkText,
            secondary: colors.greyText,
            disabled: colors.lightGreyText,
        },
        action: {
            active: colors.primary,
        },
        divider: colors.light,
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    left: -16,
                    '.form--white &': {
                        color: `${colors.white} !important`,
                    },

                    '&.Mui-focused': {
                        color: `${colors.primary} !important`,
                        '.form--white &': {
                            color: `${colors.white} !important`,
                        },
                    },
                    '&.Mui-error': {
                        color: `${colors.error} !important`,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 150,
                    borderRadius: 3,
                    minHeight: 40,
                    textTransform: 'none',
                    color: colors.greyText,
                    fontWeight: fontWeightRegular,
                },
                contained: {
                    backgroundColor: colors.lightGreyText,
                    color: colors.primary,
                    fontSize: 12,
                    lineHeight: 1,
                    boxShadow: 'none',
                    fontWeight: fontWeightBold,
                    '&:hover': {
                        backgroundColor: colors.greyText,
                    },
                    '& $label svg': {
                        fontSize: 12,
                        marginLeft: 2,
                        marginTop: -2,
                    },
                },
                containedSecondary: {
                    color: colors.white,
                    backgroundColor: colors.secondary,
                    '&:hover': {
                        backgroundColor: colors.primary,
                    },
                },
                containedPrimary: {
                    color: colors.white,
                    backgroundColor: colors.primary,
                    '&:hover': {
                        backgroundColor: colors.white,
                        color: colors.primary,
                        border: `1px solid ${colors.primary}`,
                    },
                },
                textPrimary: {
                    color: colors.darkText,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    border: `1px solid ${colors.secondaryLight}`,
                    margin: '8px 0',
                    '&:before': {
                        display: 'none',
                    },

                    '&.Mui-expanded': {
                        margin: '8px 0',
                        '&:first-child': {
                            marginTop: 8,
                        },
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: '0 16px',
                    minHeight: 40,
                    '&.Mui-expanded': {
                        minHeight: 40,
                    },
                },
                content: {
                    fontSize: 14,
                    color: colors.darkText,
                    fontWeight: fontWeightRegular,
                    lineHeight: '16px',
                    '&.Mui-expanded': {
                        margin: '12px 0',
                    },
                },
                expandIconWrapper: {
                    color: colors.darkText,
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: '0 16px 16px',
                    fontSize: 14,
                    lineHeight: '16px',
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                entered: {
                    overflow: 'visible',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    border: 'none',
                    borderBottomColor: colors.lightGreyText,
                },
                input: {
                    padding: '6px 0 7px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    left: -14,
                    top: 8,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottomColor: colors.lightGreyText,
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottomColor: colors.lightGreyText,
                    },
                    '&.Mui-focused:before': {
                        borderBottomColor: colors.lightGreyText,
                    },
                    '.form--white &': {
                        '&:before': {
                            borderBottomColor: alpha(colors.white, 0.42),
                        },
                        '&:hover:not($disabled):not($focused):not($error):before':
                            {
                                borderBottomColor: colors.white,
                            },
                        '&.Mui-focused:before': {
                            borderBottomColor: colors.white,
                        },
                    },
                },
                input: {
                    '.form--white &': {
                        color: colors.white,
                    },
                    height: 'auto',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    border: 'none',
                    borderBottom: `1px solid ${colors.lightGreyText}`,
                    padding: 0,
                },
                input: {
                    padding: '16px 0 7px',
                },
                notchedOutline: {
                    border: 'none',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: '18px 14px 7px 0',
                },
                icon: {
                    top: 'calc(50% - .3em)',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                caption: {
                    color: colors.greyText,
                    fontWeight: fontWeightLight,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    height: '100%',
                    minHeight: 'calc(100vh - 48px)',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '4px 24px',
                },
                head: {
                    color: colors.greyText,
                    fontSize: '0.75rem',
                    fontWeight: 500,
                },
                body: {
                    color: colors.primary,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: colors.greyText,
                },
                select: {
                    padding: '8px 14px 7px 0 !important',
                },
                selectIcon: {
                    top: 5,
                    right: -5,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow:
                        '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
                },
                elevation2: {
                    boxShadow:
                        '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: `${colors.secondary} !important`,
                },
            },
        },
    },
});
