/* eslint-disable  react/prop-types */
import * as React from 'react';
import { DoctorScoresProgress as DoctorScoresProgressComponent } from 'components/DoctorScores';
import { useTranslate } from 'hooks/useTranslate';
import classnames from 'classnames';
import useLocationTimezone from 'hooks/useLocationTimezone';
import styles from './DoctorScores.module.scss';

interface DoctorScoresProgressProps {
    className: string | null;
}

const DoctorScoresProgress: React.FC<DoctorScoresProgressProps> = ({
    className,
}) => {
    const translate = useTranslate();
    const { locationId, timezone } = useLocationTimezone();

    return (
        <div className={classnames(className, styles.root)}>
            <DoctorScoresProgressComponent
                translate={translate}
                locationId={locationId}
                timezone={timezone}
            />
        </div>
    );
};

export default DoctorScoresProgress;
