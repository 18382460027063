import * as React from 'react';
import Typography from '@mui/material/Typography';
import { TranslateFunction } from '@bondvet/web-app-i18n/util';
import useImpactScoresV2 from './useImpactScoresV2';
import ExtendedProgress from '../ExtendedProgress';
import Confetti from '../Confetti/Confetti';
import LightningIcon from '../../assets/lightning.png';
import { useStyles } from './styles';

export interface DoctorScoresProps {
    locationId: string;
    translate: TranslateFunction;
    timezone: string;
}

export default function DoctorScores({
    locationId,
    translate,
    timezone,
}: DoctorScoresProps) {
    const { classes } = useStyles();
    const impactScores = useImpactScoresV2(locationId, timezone);
    const [showConfetti, setShowConfetti] = React.useState(true);

    React.useEffect(() => {
        if (showConfetti) {
            const timer = setTimeout(() => {
                setShowConfetti(false);
            }, 7500);

            return () => {
                clearTimeout(timer);
            };
        }
        return undefined;
    }, [showConfetti]);

    return (
        <div className={classes.root}>
            {(impactScores.isFirstMilestoneReached ||
                impactScores.isLastMilestoneReached) &&
                showConfetti && <Confetti />}
            <Typography variant="h1" classes={{ root: classes.title }}>
                {translate('teamBonus.todayGoals.title')}
            </Typography>
            <Typography
                variant="h1"
                classes={{ root: classes.goalReachedInfo }}
                style={{
                    opacity:
                        impactScores.loading ||
                        !impactScores.isLastMilestoneReached
                            ? 0.0
                            : 1.0,
                    transition: 'opacity 2s',
                }}
            >
                {translate('teamBonus.todayGoals.stats.goalReachedInfo', {
                    percent:
                        impactScores?.progressBars?.revenue?.milestones?.[1]
                            ?.percentRevenueImpact,
                })}
            </Typography>
            <Typography
                classes={{ root: classes.subtitle }}
                style={{
                    opacity: impactScores?.loading ? 0.0 : 1.0,
                }}
                dangerouslySetInnerHTML={{
                    __html: translate(
                        // eslint-disable-next-line no-nested-ternary
                        impactScores.isFirstMilestoneReached
                            ? impactScores.isLastMilestoneReached
                                ? 'teamBonus.todayGoals.goalReachedSubtitle'
                                : 'teamBonus.todayGoals.firstMilestoneReachedSubtitle'
                            : 'teamBonus.todayGoals.subtitle',
                        {
                            icon: LightningIcon,
                            percent: impactScores.isFirstMilestoneReached
                                ? impactScores.progressBars?.revenue
                                      ?.milestones?.[1]?.percentRevenueImpact
                                : impactScores.progressBars?.revenue
                                      ?.milestones?.[0]?.percentRevenueImpact,
                        },
                    ).toString(),
                }}
            />
            <div className={classes.processesContainer}>
                <div className={classes.processContainer}>
                    <Typography classes={{ root: classes.label }}>
                        {translate('teamBonus.todayGoals.visits')}
                    </Typography>

                    <ExtendedProgress
                        loading={impactScores.loading}
                        milestones={
                            impactScores?.progressBars?.visits?.milestones || []
                        }
                        milestoneIcons={[
                            <img
                                src={LightningIcon}
                                key="milestoneIcon1"
                                alt="lightning"
                            />,
                            <img
                                src={LightningIcon}
                                key="milestoneIcon2"
                                alt="lightning"
                            />,
                        ]}
                        label={impactScores?.progressBars?.visits?.label || ''}
                        value={impactScores?.progressBars?.visits?.value || 0}
                        percent={
                            impactScores?.progressBars?.visits?.percent || 0
                        }
                        classes={{
                            root: classes.me,
                            bar: classes.bar,
                            success: classes.success,
                        }}
                    />
                </div>

                <div className={classes.processContainer}>
                    <Typography classes={{ root: classes.label }}>
                        {translate('teamBonus.todayGoals.revenue')}
                    </Typography>

                    <ExtendedProgress
                        loading={impactScores.loading}
                        milestones={
                            impactScores?.progressBars?.revenue?.milestones ||
                            []
                        }
                        milestoneIcons={[
                            <img
                                src={LightningIcon}
                                key="milestoneIcon1"
                                alt="lightning"
                            />,
                            <img
                                src={LightningIcon}
                                key="milestoneIcon2"
                                alt="lightning"
                            />,
                        ]}
                        value={impactScores?.progressBars?.revenue?.value || 0}
                        label={impactScores?.progressBars?.revenue?.label || ''}
                        percent={
                            impactScores?.progressBars?.revenue?.percent || 0
                        }
                        classes={{
                            root: classes.me,
                            bar: classes.bar,
                            success: classes.success,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
