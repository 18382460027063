/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import classnames from 'classnames';
import { Milestone } from '../DoctorScores/useImpactScoresV2';
import { animationKeyframes, useStyles } from './styles';

interface ExtendedProgressProps {
    percent: number;
    value: number;
    milestones: Milestone[];
    milestoneIcons: React.ReactNode[];
    hideLabel?: boolean;
    loading?: boolean;
    label: string;
    classes?: {
        root?: string;
        bar?: string;
        success?: string;
    };
}

const MILESTONE_1_WIDTH = '3rem';
const MILESTONE_1_PERCENT = 40;
const MILESTONE_2_WIDTH = '4.25rem';
const MILESTONE_2_PERCENT = 68;
const PERCENT_MAX = 92;

export default function ExtendedProgress({
    hideLabel,
    milestones,
    milestoneIcons,
    loading,
    percent,
}: ExtendedProgressProps) {
    const { classes } = useStyles();

    const barWidth = React.useMemo(() => {
        if (loading) return 0;

        const percentM1 = milestones[0]?.percent || 0;
        const percentM2 = milestones[1]?.percent || 0;

        if (percentM1 + percentM2 === 0) {
            // no milestones set - don't show progress
            return 0;
        }

        // distance between beginning and milestone 1
        let leftDistance = `calc(${
            Math.min(percent, percentM1) / percentM1
        } * ${MILESTONE_1_PERCENT}%`;

        // width of milestone 1
        if (percent >= percentM1) {
            leftDistance += ` + ${MILESTONE_1_WIDTH}`;
        }

        // width of milestone 2
        if (percent >= percentM2) {
            leftDistance += ` + ${MILESTONE_2_WIDTH}`;
        }

        // when the milestone is exactly reached, hide the bar
        if (percent === percentM1 || percent === percentM2) {
            leftDistance += ` / 2`;
        }

        // distance between milestone 1 and 2
        leftDistance += ` + ${Math.max(
            (Math.min(percent, percentM2) - percentM1) /
                (percentM2 - percentM1),
            0.0,
        )} * (${
            MILESTONE_2_PERCENT - MILESTONE_1_PERCENT
        }% - ${MILESTONE_1_WIDTH})`;

        // distance between behind milestone 2
        leftDistance += ` + ${Math.max(
            (Math.min(percent, 100) - percentM2) / (100 - percentM2),
            0.0,
        )} * (${PERCENT_MAX - MILESTONE_2_PERCENT}% - ${MILESTONE_2_WIDTH})`;
        return `${leftDistance})`;
    }, [milestones, percent, loading]);

    const milestoneHit =
        percent > 0 && percent >= (milestones?.[0]?.percent || 0.0);

    const barStyle = {
        '--bar-width': barWidth,
        '--bar-color': milestoneHit ? '#48D2A0' : undefined,
        '--bar-start-color': milestoneHit ? '#48D2A0' : undefined,
    } as React.CSSProperties;
    return (
        <div
            className={classnames(classes.root, {
                [classes.success || '']: percent >= 100,
                [classes.hideLabel]: hideLabel,
            })}
        >
            <style>{animationKeyframes}</style>
            <div className={classes.bar} style={barStyle} />
            {milestones.map((milestone, index) => {
                const firstMilestone =
                    index === 0 && index !== milestones.length - 1;

                const position =
                    index === 0 ? MILESTONE_1_PERCENT : MILESTONE_2_PERCENT;
                const delay =
                    ((position * 2) / 100) *
                    (Math.min(PERCENT_MAX, percent) / 100);

                return (
                    <div
                        key={
                            // eslint-disable-next-line react/no-array-index-key
                            `milestone-${index}`
                        }
                        className={classnames(classes.milestone, {
                            [classes.lastMilestone]:
                                index === milestones.length - 1,
                        })}
                        style={
                            {
                                '--milestone-position': `${position}%`,
                                '--milestone-color':
                                    !loading && milestone.hit
                                        ? '#48D2A0'
                                        : undefined,
                                '--milestone-right':
                                    !loading && milestone.hit ? 0 : undefined,
                                '--milestone-delay': `${delay.toFixed(3)}s`,
                                opacity: milestone.hide ? 0.0 : 1.0,
                            } as React.CSSProperties
                        }
                    >
                        <span
                            className={classnames(classes.milestoneIcon, {
                                [classes.animationFirstMilestoneIcon]:
                                    firstMilestone,
                                [classes.animationLastMilestoneIcon]:
                                    !firstMilestone,
                                [classes.milestoneLoading]: loading,
                            })}
                            style={{
                                animationName: firstMilestone
                                    ? '$animationFirstMilestoneIcon'
                                    : '$animationLastMilestoneIcon',
                            }}
                        >
                            {milestoneIcons?.[index]}
                        </span>
                        {milestone.value !== undefined && (
                            <>
                                <span
                                    className={classnames(
                                        classes.milestonePercent,
                                        {
                                            [classes.milestonePercentSmall]:
                                                firstMilestone,
                                            [classes.milestonePercentBig]:
                                                !firstMilestone,
                                        },
                                    )}
                                >
                                    {milestone.percentRevenueImpact}
                                </span>
                                <label
                                    className={classnames(
                                        classes.milestoneLabel,
                                    )}
                                >
                                    {milestone.label}
                                </label>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

ExtendedProgress.defaultProps = {
    hideLabel: false,
};
