import * as React from 'react';
import styles from './ScreenImage.module.scss';

interface ScreenImageProps {
    image: string;
    onError: () => void;
    active: boolean;
}

function ScreenImage({ image, onError, active }: ScreenImageProps) {
    const [loadFailed, setLoadFailed] = React.useState(false);

    const handleLoadError = React.useCallback(() => {
        setLoadFailed(true);
    }, []);

    React.useEffect(() => {
        if (loadFailed && active) {
            onError();
        }
    }, [active, loadFailed, onError]);

    return (
        <img
            className={styles.root}
            src={image}
            alt="full screen image"
            onError={handleLoadError}
        />
    );
}

export default ScreenImage;
