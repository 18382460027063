import * as React from 'react';
import ScreenImage from 'components/ScreenImage/ScreenImage';
import LatestReviews from 'pages/LatestReviews';
import { DoctorScoresOverview, DoctorScoresProgress } from 'pages/DoctorScores';
import ClientHappiness from 'pages/ClientHappiness';
import useUpdates from 'hooks/useUpdates';
import useLocationId from 'hooks/useLocationId';
import classnames from 'classnames';
import styles from './SlideController.module.scss';

const SLIDE_TIMER_INTERVAL = 12 * 1000;
const RELOAD_TIMER_INTERVAL = 15 * 60 * 1000;
const POLL_INTERVAL = 10 * 60 * 1000;

export default function SlideController() {
    const slideTimer = React.useRef<NodeJS.Timeout | null>(null);
    const reloadTimer = React.useRef<NodeJS.Timeout | null>(null);
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [updateSlides, setUpdateSlides] = React.useState<
        React.ReactElement[]
    >([]);
    const locationId = useLocationId();
    const { runQuery } = useUpdates(locationId);

    const pageSlides = React.useMemo(
        () => [
            <LatestReviews
                key="latest_reviews_page"
                className={styles.route}
            />,
            <DoctorScoresProgress
                key="doctor_progress_page"
                className={styles.route}
            />,
            <DoctorScoresOverview
                key="doctor_overview_page"
                className={styles.route}
            />,
            <ClientHappiness
                key="client_happiness_page"
                className={styles.route}
            />,
        ],
        [],
    );

    const slides = React.useMemo(() => {
        return [...pageSlides, ...updateSlides];
    }, [pageSlides, updateSlides]);

    const showNextSlide = React.useCallback(() => {
        setCurrentSlide((currentSlide2) => {
            return (currentSlide2 + 1) % slides.length;
        });
    }, [slides.length]);

    React.useEffect(() => {
        let interval: number;
        const loadData = async () => {
            const locationUpdates = await runQuery();

            if (locationUpdates) {
                const updateScreens = locationUpdates.map(
                    ({ image }, index) => (
                        <ScreenImage
                            key={`location_update_${index}`}
                            image={image?.url ?? ''}
                            onError={showNextSlide}
                            active={false}
                        />
                    ),
                );
                setUpdateSlides(updateScreens);
            }
        };

        loadData().then(() => {
            interval = window.setInterval(loadData, POLL_INTERVAL);
        });

        return () => {
            if (interval) {
                window.clearInterval(interval);
            }
        };
    }, [runQuery, showNextSlide]);

    React.useEffect(() => {
        const startSlideRotation = () => {
            if (!slideTimer.current) {
                slideTimer.current = setInterval(
                    showNextSlide,
                    SLIDE_TIMER_INTERVAL,
                );
            }
        };

        const stopSlideRotation = () => {
            if (slideTimer.current) {
                clearInterval(slideTimer.current);
                slideTimer.current = null;
            }
        };

        reloadTimer.current = setTimeout(() => {
            reloadTimer.current = null;
            window.location.reload();
        }, RELOAD_TIMER_INTERVAL);
        if (slides.length > 0) {
            startSlideRotation();
        } else {
            stopSlideRotation();
        }
        return () => {
            stopSlideRotation();
            if (reloadTimer.current) {
                clearTimeout(reloadTimer.current);
            }
        };
    }, [slides.length, showNextSlide]);

    return (
        <div className={styles.root}>
            {slides.map((slide, index) => (
                <div
                    key={`page_${index}`}
                    className={classnames(styles.pageWrapper, {
                        [styles.activePage]: index === currentSlide,
                    })}
                >
                    {React.cloneElement(slide, {
                        ...slide.props,
                        active: index === currentSlide,
                    })}
                </div>
            ))}
        </div>
    );
}
