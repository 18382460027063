import * as React from 'react';
import { TranslateFunction } from '@bondvet/web-app-i18n/util';
import Typography from '@mui/material/Typography';
import Survey from '../Survey';
import useSurveys from './useSurveys';
import { useStyles } from './styles';

interface ClientHappinessProps {
    locationId: string;
    translate: TranslateFunction;
}

export default function ClientHappiness({
    translate,
    locationId,
}: ClientHappinessProps) {
    const { classes } = useStyles();
    const surveys = useSurveys(locationId);

    return (
        <div className={classes.root}>
            <Typography variant="h2" classes={{ root: classes.label }}>
                {translate('teamBonus.npsScores.label')}
            </Typography>
            <Typography variant="h1" classes={{ root: classes.title }}>
                {translate('teamBonus.npsScores.title')}
            </Typography>

            <ul className={classes.surveys}>
                {surveys.map((survey) => (
                    <Survey key={survey._id} survey={survey} />
                ))}
            </ul>
        </div>
    );
}
