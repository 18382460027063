import * as React from 'react';
import { useTranslate } from 'hooks/useTranslate';
import classnames from 'classnames';
import GoogleReviews from 'components/GoogleReviews';
import useLocationId from 'hooks/useLocationId';
import styles from './LatestReviews.module.scss';

interface LatestReviewsProps {
    className: string | null;
}

export default function LatestReviews({ className }: LatestReviewsProps) {
    const translate = useTranslate();
    const locationId = useLocationId();

    return (
        <div className={classnames(className, styles.root)}>
            <GoogleReviews locationId={locationId} translate={translate} />
        </div>
    );
}
