import * as React from 'react';
import { useTranslate } from 'hooks/useTranslate';
import classnames from 'classnames';
import ClientHappinessComponent from 'components/ClientHappiness';
import useLocationId from 'hooks/useLocationId';
import styles from './ClientHappiness.module.scss';

interface ClientHappinessProps {
    className: string | null;
}

export default function ClientHappiness({ className }: ClientHappinessProps) {
    const translate = useTranslate();
    const locationId = useLocationId();

    return (
        <div className={classnames(className, styles.root)}>
            <ClientHappinessComponent
                locationId={locationId}
                translate={translate}
            />
        </div>
    );
}
