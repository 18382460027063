import * as React from 'react';
import { TranslateFunction } from '@bondvet/web-app-i18n/util';
import Typography from '@mui/material/Typography';
import useReviews from './useReviews';
import AverageRating from '../AverageRating';
import Review from '../Review';
import { useStyles } from './styles';

interface GoogleReviewsProps {
    locationId: string;
    translate: TranslateFunction;
}

export default function GoogleReviews({
    locationId,
    translate,
}: GoogleReviewsProps) {
    const { classes } = useStyles();
    const { averageRating, reviews } = useReviews(locationId);
    return (
        <div className={classes.root}>
            <div>
                <Typography variant="h1" classes={{ root: classes.title }}>
                    {translate('teamBonus.googleReviews.title')}
                </Typography>
                {reviews.map((review) => (
                    <Review key={review._id} review={review} />
                ))}
            </div>
            {averageRating && <AverageRating rating={averageRating} />}
        </div>
    );
}
