import * as React from 'react';
import { useStyles } from './styles';
import Stars from '../Stars';

interface AverageRatingProps {
    rating: number;
}

export default function AverageRating({ rating }: AverageRatingProps) {
    const { classes } = useStyles();
    return (
        <div className={classes.root}>
            <span className={classes.rating}>
                {rating.toLocaleString(undefined, {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                })}
            </span>
            <Stars score={rating} classes={{ root: classes.stars }} />
        </div>
    );
}
