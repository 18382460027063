import * as React from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { GoogleReview } from '../Review/types';
import { googleReviews } from '../../api/queries';

type RawGoogleReview = {
    _id: string;
    reviewer?: {
        displayName?: string;
    };
    date: string;
    rating: number;
};

type GoogleReviewsQuery = {
    googleReviews: {
        reviews: RawGoogleReview[];
        averageRating: string;
        totalReviewCount: number;
    };
};

type GoogleReviewsVariables = {
    locationId: string;
};

export type GoogleReviewsData = {
    averageRating: number | null;
    reviews: GoogleReview[];
};

export default function useReviews(locationId: string): GoogleReviewsData {
    const [state, setState] = React.useState<GoogleReviewsData>({
        averageRating: null,
        reviews: [],
    });
    const { data } = useQuery<GoogleReviewsQuery, GoogleReviewsVariables>(
        googleReviews,
        {
            variables: { locationId },
            fetchPolicy: 'cache-and-network',
        },
    );
    React.useEffect(() => {
        if (data?.googleReviews) {
            const averageRating = data.googleReviews.averageRating
                ? parseFloat(data.googleReviews.averageRating)
                : null;
            const sortedReviews = data.googleReviews.reviews
                .slice(0)
                .sort(
                    (a: RawGoogleReview, b: RawGoogleReview) =>
                        moment(b.date, moment.ISO_8601).valueOf() -
                        moment(a.date, moment.ISO_8601).valueOf(),
                );
            const reviews: GoogleReview[] = sortedReviews.map(
                ({ reviewer, _id, rating }: RawGoogleReview) => ({
                    _id,
                    rating,
                    name: reviewer?.displayName,
                }),
            );

            setState({
                averageRating,
                reviews,
            });
        }
    }, [data]);

    return state;
}
