import * as React from 'react';
import { GoogleReview } from './types';
import Stars from '../Stars';
import { useStyles } from './styles';

interface ReviewProps {
    review: GoogleReview;
}

export default function Review({ review }: ReviewProps) {
    const { classes } = useStyles();
    return (
        <li className={classes.root}>
            <Stars score={review.rating} />
            <span className={classes.name}>{review.name}</span>
        </li>
    );
}
