import { makeStyles } from 'tss-react/mui';
import { keyframes } from '@emotion/react';

function random(limit: number): number {
    return Math.round(Math.random() * limit);
}

const COLORS: string[] = [
    'var(--primary-color)',
    'var(--secondary-color)',
    'var(--success-color)',
    'var(--error-color)',
    'var(--warning-color)',
];

const randomColor = () => COLORS[random(COLORS.length - 1)];

interface Snippet {
    key: string;
    width: number;
    left: number;
    duration: string;
    delay: string;
}

export function stylesFactory(keys: string[]) {
    const snippets = keys.map<Snippet>((key) => ({
        key,
        width: Math.max(5, random(12)),
        left: random(100),
        duration: (4 + Math.random()).toFixed(2),
        delay: (Math.random() * 3 + 0.2).toFixed(2),
    }));

    const keyframeStyles = snippets.reduce<Record<string, any>>(
        (acc, { key, left }) => ({
            ...acc,
            [key]: keyframes`
                    100% {
                        top: 110%;
                        left: ${(left + random(15)).toString(10)}%;
                    }
                `,
        }),
        {},
    );

    const keyframesCSS = snippets
        .map(({ key }) => keyframeStyles[key])
        .join('\n');

    const useStyles = makeStyles()((theme) =>
        snippets.reduce(
            (result, { key, width, left, duration, delay }) => {
                return {
                    ...result,
                    [key]: {
                        width: theme.typography.pxToRem(width),
                        height: theme.typography.pxToRem(width * 0.4),
                        backgroundColor: randomColor(),
                        left: `${left.toString(10)}%`,
                        opacity: Math.min(Math.random() + 0.5, 1).toFixed(2),
                        transform: `rotate(${random(360).toString(10)}deg)`,
                        animation: `${keyframeStyles[key]} ${duration}s ${delay}s infinite`,
                    },
                };
            },
            {
                confetti: {
                    position: 'absolute',
                    top: '-10%',
                    zIndex: 100,
                },
            },
        ),
    );

    return {
        keyframesCSS,
        useStyles,
    };
}
