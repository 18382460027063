import * as React from 'react';
import classnames from 'classnames';
import { stylesFactory } from './styles';

const range: string[] = [];

const AMOUNT = 200;

for (let i = 0; i < AMOUNT; i++) {
    range.push(i.toString(16));
}

const { keyframesCSS, useStyles } = stylesFactory(range);

export default function Confetti() {
    const { classes } = useStyles();

    return (
        <div>
            <style>{keyframesCSS}</style>
            {range.map((key: string) => (
                <span
                    key={key}
                    className={classnames(
                        classes.confetti,
                        (classes as Record<string, string>)[key],
                    )}
                >
                    <i />
                </span>
            ))}
        </div>
    );
}
