import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'inline-flex',
        alignItems: 'baseline',
        fontSize: theme.typography.pxToRem(16),
    },
    star: {
        fontSize: 'inherit',
    },
    starFull: {},
}));
