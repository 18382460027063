import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: {
        width: theme.typography.pxToRem(562),
    },
    label: {
        textTransform: 'uppercase',
        fontSize: theme.typography.pxToRem(15),
        lineHeight: 19 / 15,
        letterSpacing: theme.typography.pxToRem(2.5),
        fontWeight: theme.typography.fontWeightBold as number,
        marginBottom: theme.typography.pxToRem(6),
    },
    title: {
        fontSize: theme.typography.pxToRem(30),
        lineHeight: 31 / 30,
        fontWeight: theme.typography.fontWeightBold as number,
        letterSpacing: theme.typography.pxToRem(-0.01),
        marginBottom: theme.typography.pxToRem(26),
    },
    surveys: {
        listStyle: 'none',
        columns: 2,
        columnGap: theme.typography.pxToRem(77),
        margin: 0,
        padding: 0,
        flexDirection: 'column',
        flexWrap: 'wrap',
        '& > li': {
            display: 'inline-flex',
        },
    },
}));
