import * as React from 'react';
import moment from 'moment-timezone';
import {
    ImpactScoreV2QueryVariables,
    ImpactScoreV2QueryResult,
    ImpactScoreV2,
} from '@bondvet/types/gainSharing';
import { impactScoreV2 } from 'api/queries';
import useGainSharingV2Query from 'api/useGainSharingV2Query';
import { useLocation } from 'react-router-dom';
import mockData from './mockData.json';

export interface Milestone {
    value?: number;
    label?: string;
    icon?: string | React.ReactNode;
    percent?: number;
    percentRevenueImpact?: string;
    hide?: boolean;
    hit?: boolean;
}

export interface ImpactScore {
    value?: number;
    label?: string;
    percent?: number;
    amountToGo?: string;
    milestones?: Milestone[];
}

export interface ImpactScoresV2 {
    progressBars: {
        visits: ImpactScore;
        revenue: ImpactScore;
    };
    payoutOfMonth?: string;
    payout?: string;
    monthName?: string;
    locationName?: string;
    isFirstMilestoneReached: boolean;
    isLastMilestoneReached: boolean;
    loading: boolean;
}

const VISITS_LABEL = 'visits';
const REVENUE_LABEL = 'revenue';

export default function useImpactScoresV2(
    locationId: string,
    timezone: string,
): ImpactScoresV2 {
    const { search } = useLocation();
    const params = React.useMemo(() => {
        const query = new URLSearchParams(search);
        return {
            day: query.get('day'),
            version: query.get('version'),
            mockData: query.get('mock-data'),
            mock: query.get('mock'),
        };
    }, [search]);
    const day = moment.tz(timezone);

    const { data, loading } = useGainSharingV2Query<
        ImpactScoreV2QueryResult,
        ImpactScoreV2QueryVariables
    >(impactScoreV2, {
        variables: {
            locationId,
            date: params.day || day.format('YYYY-MM-DD'),
        },
        fetchPolicy: 'network-only',
    });

    let apiData: ImpactScoreV2QueryResult | undefined;
    if (
        params.mockData === 'true' ||
        (!params.mockData && process.env.REACT_APP_DEBUG === 'true')
    ) {
        apiData = {
            impactScoreV2: (
                mockData as unknown as Record<string, ImpactScoreV2>
            )[params.mock || mockData.current],
        };
    } else {
        apiData = data;
    }

    const formatRevenue = React.useCallback(
        (revenue?: number) =>
            '$' + Math.round(revenue || 0).toLocaleString('en-US'),
        [],
    );

    return React.useMemo(() => {
        const isFirstMilestoneReached =
            !!apiData?.impactScoreV2?.milestone1Reached;
        const isLastMilestoneReached =
            !!apiData?.impactScoreV2?.milestone2Reached;

        return {
            loading,
            locationName: apiData?.impactScoreV2?.locationName,
            isFirstMilestoneReached,
            isLastMilestoneReached,
            monthName: day.format('MMMM'),
            payoutOfMonth: formatRevenue(apiData?.impactScoreV2?.payoutOfMonth),
            payout: formatRevenue(apiData?.impactScoreV2?.payout),
            progressBars: {
                visits: {
                    value: apiData?.impactScoreV2?.visits,
                    percent: apiData?.impactScoreV2?.visitsPercent,
                    label: apiData?.impactScoreV2?.visits.toString(),
                    amountToGo: (
                        (apiData?.impactScoreV2?.visitsMilestone2 || 0) -
                        (apiData?.impactScoreV2?.visits || 0)
                    ).toString(),
                    milestones: [
                        {
                            icon: '\uD83D\uDD11',
                            value: apiData?.impactScoreV2?.visitsMilestone1,
                            label: apiData?.impactScoreV2?.visitsMilestone1.toString(),
                            percent:
                                apiData?.impactScoreV2?.visitsMilestone1Percent,
                            percentRevenueImpact:
                                (apiData?.impactScoreV2
                                    ?.bonusPercentMilestone1 || '') + '%',
                            hit:
                                apiData?.impactScoreV2?.milestone1Reached ===
                                VISITS_LABEL,
                            hide:
                                apiData?.impactScoreV2?.milestone1Reached ===
                                REVENUE_LABEL,
                        },
                        {
                            icon: '\uD83D\uDD11',
                            value: apiData?.impactScoreV2?.visitsMilestone2,
                            label: apiData?.impactScoreV2?.visitsMilestone2.toString(),
                            percent:
                                apiData?.impactScoreV2?.visitsMilestone2Percent,
                            percentRevenueImpact:
                                (apiData?.impactScoreV2
                                    ?.bonusPercentMilestone2 || '') + '%',
                            hit:
                                apiData?.impactScoreV2?.milestone2Reached ===
                                VISITS_LABEL,
                            hide:
                                apiData?.impactScoreV2?.milestone2Reached ===
                                REVENUE_LABEL,
                        },
                    ],
                },
                revenue: {
                    value: apiData?.impactScoreV2?.revenuePerDoctor,
                    label: formatRevenue(
                        apiData?.impactScoreV2?.revenuePerDoctor,
                    ),
                    percent: apiData?.impactScoreV2?.revenuePerDoctorPercent,
                    amountToGo: formatRevenue(
                        (apiData?.impactScoreV2?.revenuePerDoctorMilestone2 ||
                            0) -
                            (apiData?.impactScoreV2?.revenuePerDoctor || 0),
                    ),
                    milestones: [
                        {
                            icon: '\uD83D\uDD11',
                            value: apiData?.impactScoreV2
                                ?.revenuePerDoctorMilestone1,
                            label: formatRevenue(
                                apiData?.impactScoreV2
                                    ?.revenuePerDoctorMilestone1,
                            ),
                            percent:
                                apiData?.impactScoreV2
                                    ?.revenuePerDoctorMilestone1Percent,
                            percentRevenueImpact:
                                (apiData?.impactScoreV2
                                    ?.bonusPercentMilestone1 || '') + '%',
                            hit:
                                apiData?.impactScoreV2?.milestone1Reached ===
                                REVENUE_LABEL,
                            hide:
                                apiData?.impactScoreV2?.milestone1Reached ===
                                VISITS_LABEL,
                        },
                        {
                            icon: '\uD83D\uDD11',
                            value: apiData?.impactScoreV2
                                ?.revenuePerDoctorMilestone2,
                            label: formatRevenue(
                                apiData?.impactScoreV2
                                    ?.revenuePerDoctorMilestone2,
                            ),
                            percent:
                                apiData?.impactScoreV2
                                    ?.revenuePerDoctorMilestone2Percent,
                            percentRevenueImpact:
                                (apiData?.impactScoreV2
                                    ?.bonusPercentMilestone2 || '') + '%',
                            hit:
                                apiData?.impactScoreV2?.milestone2Reached ===
                                REVENUE_LABEL,
                            hide:
                                apiData?.impactScoreV2?.milestone2Reached ===
                                VISITS_LABEL,
                        },
                    ],
                },
            },
        };
    }, [formatRevenue, apiData, day, loading]);
}
