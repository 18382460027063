import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'baseline',
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(33),
        width: '100%',
        '& > *': {
            display: 'block',
        },
    },
    score: {
        fontWeight: theme.typography.fontWeightBold as number,
        marginRight: theme.typography.pxToRem(26),
        textAlign: 'right',
        width: '1.1em',
    },
    name: {
        fontWeight: theme.typography.fontWeightBold as number,
    },
    time: {
        fontWeight: theme.typography.fontWeightRegular as number,
        fontSize: theme.typography.pxToRem(14),
        marginLeft: 'auto',
        textAlign: 'right',
    },
}));
