import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: theme.typography.pxToRem(14),
    },
    stars: {},
    name: {
        fontWeight: theme.typography.fontWeightRegular as number,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: 24 / 18,
        marginLeft: theme.typography.pxToRem(14),
    },
}));
