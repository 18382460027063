import {
    type QueryResult,
    type QueryHookOptions,
    useQuery,
    type TypedDocumentNode,
    type OperationVariables,
} from '@apollo/client';

export default function useGainSharingV2Query<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
    return useQuery<TData, TVariables>(query, {
        ...options,
        context: {
            ...options?.context,
            clientName: 'gainSharing',
        },
    });
}
