import * as React from 'react';

export default function useLoadedMessage(): void {
    React.useEffect(() => {
        if (window.self !== window.parent) {
            // we're in an iframe => post the "loaded" event
            window.parent.postMessage('loaded', '*');
        }
    }, []);
}
