import * as React from 'react';
import { SurveyData } from './types';
import { useStyles } from './styles';

interface SurveyProps {
    survey: SurveyData;
}

export default function Survey({
    survey: { score, firstName, date },
}: SurveyProps) {
    const { classes } = useStyles();
    return (
        <li className={classes.root}>
            <strong className={classes.score}>{score}</strong>
            <span className={classes.name}>{firstName}</span>
            <small className={classes.time}>{date.fromNow()}</small>
        </li>
    );
}
