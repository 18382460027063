import * as React from 'react';
import Typography from '@mui/material/Typography';
import { TranslateFunction } from '@bondvet/web-app-i18n/util';
import useImpactScores from './useImpactScoresV2';
import LightningIcon from '../../assets/lightning.png';
import FireIcon from '../../assets/fire.png';
import TadaIcon from '../../assets/tada.png';
import { useStyles } from './styles';

export interface DoctorScoresOverviewProps {
    locationId: string;
    translate: TranslateFunction;
    timezone: string;
}

export default function DoctorScoresOverview({
    locationId,
    translate,
    timezone,
}: DoctorScoresOverviewProps) {
    const { classes } = useStyles();
    const impactScores = useImpactScores(locationId, timezone);

    if (impactScores.loading) return null;

    return (
        <div className={classes.root}>
            <Typography variant="h1" classes={{ root: classes.title }}>
                {translate(
                    impactScores.isLastMilestoneReached
                        ? 'teamBonus.todayGoals.stats.title'
                        : 'teamBonus.todayGoals.title',
                )}
            </Typography>
            {impactScores.isLastMilestoneReached && (
                <Typography
                    variant="h1"
                    classes={{ root: classes.goalReachedInfo }}
                >
                    {translate('teamBonus.todayGoals.stats.goalReachedInfo', {
                        percent:
                            impactScores?.progressBars?.revenue?.milestones?.[1]
                                ?.percentRevenueImpact,
                    })}
                </Typography>
            )}

            <Typography
                classes={{ root: classes.subtitle }}
                dangerouslySetInnerHTML={{
                    __html: translate(
                        impactScores.isLastMilestoneReached
                            ? 'teamBonus.todayGoals.goalReachedSubtitle'
                            : 'teamBonus.todayGoals.firstMilestoneReachedSubtitle',
                        {
                            icon: LightningIcon,
                            percent:
                                impactScores?.progressBars?.revenue
                                    ?.milestones?.[1]?.percentRevenueImpact,
                        },
                    ).toString(),
                }}
            />

            <div className={classes.center}>
                <Typography
                    variant="h1"
                    classes={{ root: classes.earnedAmount }}
                >
                    {impactScores.payout}
                </Typography>
                <Typography
                    variant="h2"
                    classes={{ root: classes.title }}
                    dangerouslySetInnerHTML={{
                        __html: translate(
                            'teamBonus.todayGoals.stats.amountDescription',
                            { icon: TadaIcon },
                        ).toString(),
                    }}
                />
            </div>

            <div className={classes.footer}>
                <Typography className={classes.monthText}>
                    {translate('teamBonus.todayGoals.stats.monthText', {
                        locationName: impactScores.locationName,
                        month: impactScores.monthName,
                    })}
                </Typography>
                <div>
                    <Typography className={classes.monthNumber}>
                        {impactScores.payoutOfMonth}
                    </Typography>
                    <Typography
                        className={classes.monthNumberText}
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                'teamBonus.todayGoals.stats.amountEarned',
                                { icon: FireIcon },
                            ).toString(),
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
