import { makeStyles } from 'tss-react/mui';

const icon = {
    height: '2rem',
    marginBottom: '-0.5rem',
};

export const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.typography.pxToRem(32),
    },
    title: {
        fontWeight: (theme.typography as any).fontWeightBold,
        fontSize: theme.typography.pxToRem(30),
        lineHeight: 39 / 30,
        marginBottom: theme.typography.pxToRem(9),
        '& img': {
            height: '2.2rem',
            marginBottom: '-0.65rem',
        },
    },
    subtitle: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: (theme.typography as any).fontWeightMedium,
        '& strong': {
            color: 'var(--secondary-color)',
            fontWeight: 'inherit',
        },
        transition: 'opacity 2s',
        '& img': icon,
    },
    processesContainer: {
        position: 'absolute',
        top: '26.5%',
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    processContainer: {
        marginTop: theme.typography.pxToRem(32),
        width: '100%',
        maxWidth: theme.typography.pxToRem(613),
    },
    label: {
        fontWeight: theme.typography.fontWeightBold as number,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1,
        marginBottom: theme.typography.pxToRem(4),
        letterSpacing: theme.typography.pxToRem(2.5),
        textTransform: 'uppercase',
        color: '--var(primary-old-color)',
    },
    bar: {
        '& span': {
            minWidth: theme.typography.pxToRem(0),
        },
    },
    me: {
        height: theme.typography.pxToRem(32),
        marginBottom: theme.typography.pxToRem(30),
        '& $bar': {
            backgroundColor: 'var(--secondary-light-color)',
        },
    },
    success: {
        '&$me $bar': {
            backgroundColor: 'var(--success-color)',
        },
    },
    info: {
        fontSize: theme.typography.pxToRem(11),
        position: 'absolute',
        bottom: theme.typography.pxToRem(48),
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '3rem',
        background: 'var(--beige-color)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 -10px 15px 0 #f4d7c640',
        padding: '0 1rem',
    },
    monthText: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
    },
    goalReachedInfo: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
        transition: 'opacity 2s',
    },
    monthNumber: {
        display: 'inline',
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(20),
    },
    monthNumberText: {
        display: 'inline',
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(16),

        '& img': icon,
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '50%',
        '& strong': {
            color: 'var(--secondary-color)',
            fontWeight: 'inherit',
        },
        '& h1': {
            fontSize: theme.typography.pxToRem(54),
            fontWeight: 600,
        },
        '& h2': {
            fontSize: theme.typography.pxToRem(22),
        },
    },
    earnedAmount: {},
}));
