import * as React from 'react';
// LocalizeContext must not be imported from web-app-i18n because it would spin up a second instance of React.
// (that's only a dev-issue because after a build, React would be used from the peer dependencies)
import {
    LocalizeContext,
    type LocalizeContextProps,
} from 'react-localize-redux';
import { TranslateFunction } from '@bondvet/web-app-i18n/util';

export function useTranslate(): TranslateFunction {
    const { translate } =
        React.useContext<LocalizeContextProps>(LocalizeContext);

    return translate as TranslateFunction;
}
