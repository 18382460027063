import * as React from 'react';
import { getUserTimezone } from '@bondvet/web-app-lib/util/timezone';
import { useQuery } from '@apollo/client';
import useLocationId from './useLocationId';
import { locationTimezone } from '../api/queries';

type LocationIdAndTimezone = {
    locationId: string;
    timezone: string;
};

type LocationTimezoneQuery = {
    location: {
        timezone: string | null;
    };
};

type LocationTimezoneVariables = {
    locationId: string;
};

export default function useLocationTimezone(): LocationIdAndTimezone {
    const locationId = useLocationId();

    const [timezone, setTimezone] = React.useState<string>(getUserTimezone);

    const { data } = useQuery<LocationTimezoneQuery, LocationTimezoneVariables>(
        locationTimezone,
        {
            fetchPolicy: 'cache-first',
            variables: { locationId },
        },
    );

    React.useEffect(() => {
        if (data?.location?.timezone) {
            setTimezone(data.location.timezone);
        }
    }, [data]);

    return { locationId, timezone };
}
