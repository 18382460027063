import * as React from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { SurveyData } from '../Survey/types';
import { npsScores } from '../../api/queries';

type SurveyResult = {
    _id: string;
    score?: number;
    appointment?: {
        firstName: string;
    };
    date: string;
};

type NpsScores = {
    surveyResults: SurveyResult[];
};

type NpsScoresVariables = {
    locationId: string;
};

export default function useSurveys(locationId: string): SurveyData[] {
    const { data } = useQuery<NpsScores, NpsScoresVariables>(npsScores, {
        variables: { locationId },
        fetchPolicy: 'cache-and-network',
    });
    const [surveys, setSurveys] = React.useState<SurveyData[]>([]);

    React.useEffect(() => {
        if (data?.surveyResults) {
            setSurveys(
                data.surveyResults.map(
                    ({ date, appointment, ...survey }: SurveyResult) => ({
                        ...survey,
                        date: moment(date, moment.ISO_8601),
                        firstName: appointment?.firstName,
                    }),
                ),
            );
        }
    }, [data]);

    return surveys;
}
