import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        padding: `${theme.typography.pxToRem(73)} ${theme.typography.pxToRem(
            70,
        )}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold as number,
        letterSpacing: theme.typography.pxToRem(2.25),
        lineHeight: 19 / 15,
        marginBottom: theme.typography.pxToRem(31.5),
        textTransform: 'uppercase',
    },
    reviews: {},
}));
