import { Theme } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createQueryString(query: Record<string, any>): string {
    return Object.keys(query)
        .reduce(
            (result: string[], key: string) => [
                ...result,
                `${key}=${encodeURIComponent(query[key])}`,
            ],
            [''],
        )
        .join('&');
}

export const manualMode =
    !!process.env.REACT_APP_MANUAL_NEXT &&
    /^true$/i.test(process.env.REACT_APP_MANUAL_NEXT);

export const renderButton = manualMode && typeof document !== 'undefined';

export function spacing(theme: Theme, value = 1): string {
    const result =
        typeof theme.spacing === 'function'
            ? parseInt(theme.spacing(value), 10)
            : (theme.spacing as any).unit * value;

    return theme.typography.pxToRem(result);
}

export const defaultTimeoutSeconds = process.env
    .REACT_APP_DEFAULT_SCREEN_SWITCHING_SECONDS
    ? parseInt(process.env.REACT_APP_DEFAULT_SCREEN_SWITCHING_SECONDS, 10)
    : 10;
