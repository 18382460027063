import * as React from 'react';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import classnames from 'classnames';
import { useStyles } from './styles';

const RANGE: number[] = [1, 2, 3, 4, 5];

interface StarsProps {
    score?: number;
    classes?: {
        root?: string;
    };
}

export default function Stars({ score }: StarsProps) {
    const { classes } = useStyles();
    if (score && score > 0) {
        const limit = Math.round(score * 2) / 2;

        return (
            <ul className={classes.root}>
                {RANGE.map((value) => {
                    const isFull = limit + 0.5 >= value;
                    const Icon =
                        // eslint-disable-next-line no-nested-ternary
                        limit + 0.5 === value
                            ? StarHalfIcon
                            : isFull
                              ? StarIcon
                              : StarBorderIcon;

                    return (
                        <li key={value}>
                            <Icon
                                className={classnames(classes.star, {
                                    [classes.starFull]: isFull,
                                })}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }

    return null;
}
